
import {
  defineComponent, computed
} from 'vue'
import { useRoute } from 'vue-router'
import LineChart from '@/components/shared/charts/LineChart.vue'

export default defineComponent({
  components: {
    LineChart,
  },
  setup() {
    const route = useRoute()

    const year = computed(() => (Number(route.params.year)))

    const lineChartData = computed(() => ({
      labels: [
        `January ${year.value}`,
        `February ${year.value}`,
        `March ${year.value}`,
        `April ${year.value}`,
        `May ${year.value}`,
        `June ${year.value}`,
        `July ${year.value}`,
        `August ${year.value}`,
        `September ${year.value}`,
        `October ${year.value}`,
        `November ${year.value}`,
        `December ${year.value}`,
      ],
      datasets: [
        {
          data: [1550, 500, 50, 4500, 700, 4200, 1200, 1300, 1400, 900, 900, 900],
        },
      ],
    }))

    return {
      lineChartData,
    }
  },
})
