
import { defineComponent, computed } from 'vue'
import { useRoute } from 'vue-router'
import LineChart from '@/components/shared/charts/LineChart.vue'

export default defineComponent({
  components: {
    LineChart,
  },
  setup() {
    const route = useRoute()
    const monthMap = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
    const year = computed<number>(() => +route.params.year)
    const month = computed<string>(() => monthMap[+route.params.month - 1])

    const lineChartData = computed(() => ({
      labels: Array(30).fill(null).map((el: any, i: number) => `${i + 1}/${month.value}/${year.value}`),
      datasets: [
        {
          data: [
            2732, 1663, 1489, 402, 21, 657, 1152, 1955, 60, 2318,
            2185, 1671, 2450, 4251, 2181, 2342, 2448, 2685, 2354, 2975,
            2015, 1748, 3042, 3879, 2139, 2402, 769, 1320, 1547, 1888,
          ],
        },
      ],
    }))

    return {
      lineChartData,
    }
  },
})
