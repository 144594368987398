
import {
  defineComponent, ref
} from 'vue'
import LineChart from '@/components/shared/charts/LineChart.vue'

export default defineComponent({
  components: {
    LineChart,
  },
  setup() {
    const lineChartData = ref({
      labels: [
        '2013',
        '2014',
        '2015',
        '2016',
        '2017',
        '2018',
        '2019',
        '2020',
        '2021',
        '2022',
      ],
      datasets: [
        {
          data: [550, 1200, 1300, 700, 1100, 6800, 1200, 1300, 1400, 900],
        },
      ],
    })

    return {
      lineChartData,
    }
  },
})
